<template>
  <div class="pbf">
     
     <n-dialog-provider>
       <n-message-provider>
         <TabSchemes
           :data="data"
           />
       </n-message-provider>
     </n-dialog-provider>

  </div>
</template>

<script setup>

import { NDialogProvider, NMessageProvider } from 'naive-ui'
import TabSchemes from '@/pages/brands-manager/components/TabSchemes'

const props = defineProps({
  data: {
    type: Object    
  }
});

useHead(useNuxtApp().$head.getBrandManager());

</script>


<style scoped>



/**/
 
</style>

<style>

/* schemes_table */

.schemes_table .an{
  vertical-align: middle;
}

.schemes_table .ac{
  width: 150px;
  vertical-align: middle;
  text-align: center;
}

.schemes_table .as{
  width: 200px;
  vertical-align: middle;
}

.schemes_table .al{
  width: 100px;
  vertical-align: middle;
}

.pointer{
  cursor: pointer;
}

</style>